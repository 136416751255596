var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entrustChildForm"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"课程日期","rules":[
        { required: true, message: '请选择课程日期', trigger: 'blur' },
      ],"prop":"dayName"}},[_c('v-date-picker',{attrs:{"type":"date","formatValue":"yyyy-MM-dd","placeholder":"请选择课程日期","width":350},model:{value:(_vm.form.dayName),callback:function ($$v) {_vm.$set(_vm.form, "dayName", $$v)},expression:"form.dayName"}})],1),_c('el-form-item',{attrs:{"label":"报名时间","rules":[
        { required: true, message: '请选择报名时间', trigger: 'change' },
      ],"prop":"applyEndTime"}},[_c('v-date-picker',{attrs:{"formatValue":"yyyy-MM-dd","format":"yyyy-MM-dd","startPlaceholder":"报名开始时间","endPlaceholder":"报名结束时间"},model:{value:(_vm.createTime),callback:function ($$v) {_vm.createTime=$$v},expression:"createTime"}})],1),_c('el-form-item',{attrs:{"label":"可预约人数","rules":[
        { required: true, message: '请输入可预约人数', trigger: 'blur' },
        {
          message: '必须是数字格式',
          pattern: /^[0-9]+$/,
          trigger: 'blur',
        },
      ],"prop":"applicantsMax"}},[_c('v-input',{attrs:{"placeholder":"请输入可预约人数","width":350},model:{value:(_vm.form.applicantsMax),callback:function ($$v) {_vm.$set(_vm.form, "applicantsMax", $$v)},expression:"form.applicantsMax"}})],1),_c('el-form-item',{attrs:{"label":"课程时间","rules":[{ required: true, message: '请选择', trigger: 'blur' }],"prop":"timeDuring"}},[_c('v-radio',{attrs:{"radioObj":_vm.timeDuringOps},model:{value:(_vm.form.timeDuring),callback:function ($$v) {_vm.$set(_vm.form, "timeDuring", $$v)},expression:"form.timeDuring"}})],1),_c('el-form-item',{attrs:{"label":"活动场次","rules":[
        { required: true, message: '请输入活动场次', trigger: 'blur' },
      ],"prop":"detailList"}},[_c('div',{staticClass:"times"},_vm._l((_vm.form.detailList),function(item,index){return _c('div',{key:index,staticClass:"areaTimes"},[_c('div',{staticClass:"areaTimes-l"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("场次时间")]),_c('div',{staticClass:"item-r"},[_c('el-form-item',{attrs:{"rules":[
                    {
                      required: true,
                      message: '请选择场次开始时间',
                      trigger: 'blur',
                    },
                  ],"prop":`detailList.${index}.activityBeginTime`}},[_c('v-time',{attrs:{"valueFormat":"HH:mm","format":"HH:mm","pickerOptions":null,"width":190},model:{value:(item.activityBeginTime),callback:function ($$v) {_vm.$set(item, "activityBeginTime", $$v)},expression:"item.activityBeginTime"}}),_c('el-form-item',{staticStyle:{"margin-left":"20px"},attrs:{"rules":[
                      {
                        required: true,
                        message: '请选择场次结束时间',
                        trigger: 'blur',
                      },
                    ],"prop":`detailList.${index}.activityEndTime`}},[_c('v-time',{attrs:{"valueFormat":"HH:mm","format":"HH:mm","pickerOptions":null,"width":190},model:{value:(item.activityEndTime),callback:function ($$v) {_vm.$set(item, "activityEndTime", $$v)},expression:"item.activityEndTime"}})],1)],1)],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("课程名称")]),_c('div',{staticClass:"item-r"},[_c('el-form-item',{attrs:{"rules":[
                    {
                      required: true,
                      message: '请输入课程名称',
                      trigger: 'blur',
                    },
                  ],"prop":`detailList.${index}.activityName`}},[_c('v-input',{attrs:{"placeholder":"请输入课程名称","width":400,"maxlength":50},model:{value:(item.activityName),callback:function ($$v) {_vm.$set(item, "activityName", $$v)},expression:"item.activityName"}})],1)],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("课程内容")]),_c('div',{staticClass:"item-r"},[_c('el-form-item',{attrs:{"rules":[
                    {
                      required: true,
                      message: '请输入课程内容',
                      trigger: 'blur',
                    },
                  ],"prop":`detailList.${index}.activityDetail`}},[_c('v-input',{attrs:{"type":"textarea","maxlength":200,"placeholder":"请输入课程内容","width":400},model:{value:(item.activityDetail),callback:function ($$v) {_vm.$set(item, "activityDetail", $$v)},expression:"item.activityDetail"}})],1)],1)])]),_c('div',{staticClass:"areaTimes-r"},[_c('div',{staticClass:"opera"},[(index != 0)?_c('v-button',{attrs:{"text":"删除","type":"warning"},on:{"click":function($event){return _vm.outRoom(item, index)}}}):_vm._e(),(index == _vm.form.detailList.length - 1)?_c('v-button',{attrs:{"text":"新增"},on:{"click":_vm.addRoom}}):_vm._e()],1)])])}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }