<template>
  <div class="entrustChildForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="课程日期"
        :rules="[
          { required: true, message: '请选择课程日期', trigger: 'blur' },
        ]"
        prop="dayName"
      >
        <v-date-picker
          type="date"
          v-model="form.dayName"
          formatValue="yyyy-MM-dd"
          placeholder="请选择课程日期"
          :width="350"
        ></v-date-picker>
      </el-form-item>

      <el-form-item
        label="报名时间"
        :rules="[
          { required: true, message: '请选择报名时间', trigger: 'change' },
        ]"
        prop="applyEndTime"
      >
        <v-date-picker
          v-model="createTime"
          formatValue="yyyy-MM-dd"
          format="yyyy-MM-dd"
          startPlaceholder="报名开始时间"
          endPlaceholder="报名结束时间"
        />
      </el-form-item>

      <el-form-item
        label="可预约人数"
        :rules="[
          { required: true, message: '请输入可预约人数', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="applicantsMax"
      >
        <v-input
          placeholder="请输入可预约人数"
          v-model="form.applicantsMax"
          :width="350"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="课程时间"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="timeDuring"
      >
        <v-radio :radioObj="timeDuringOps" v-model="form.timeDuring"></v-radio>
      </el-form-item>

      <el-form-item
        label="活动场次"
        :rules="[
          { required: true, message: '请输入活动场次', trigger: 'blur' },
        ]"
        prop="detailList"
      >
        <div class="times">
          <div
            class="areaTimes"
            v-for="(item, index) in form.detailList"
            :key="index"
          >
            <div class="areaTimes-l">
              <div class="item">
                <div class="item-l">场次时间</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请选择场次开始时间',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`detailList.${index}.activityBeginTime`"
                  >
                    <v-time
                      v-model="item.activityBeginTime"
                      valueFormat="HH:mm"
                      format="HH:mm"
                      :pickerOptions="null"
                      :width="190"
                    ></v-time>
                    <el-form-item
                      style="margin-left: 20px"
                      :rules="[
                        {
                          required: true,
                          message: '请选择场次结束时间',
                          trigger: 'blur',
                        },
                      ]"
                      :prop="`detailList.${index}.activityEndTime`"
                    >
                      <v-time
                        v-model="item.activityEndTime"
                        valueFormat="HH:mm"
                        format="HH:mm"
                        :pickerOptions="null"
                        :width="190"
                      ></v-time>
                    </el-form-item>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">课程名称</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请输入课程名称',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`detailList.${index}.activityName`"
                  >
                    <v-input
                      placeholder="请输入课程名称"
                      v-model="item.activityName"
                      :width="400"
                      :maxlength="50"
                    ></v-input>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">课程内容</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请输入课程内容',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`detailList.${index}.activityDetail`"
                  >
                    <v-input
                      type="textarea"
                      :maxlength="200"
                      placeholder="请输入课程内容"
                      v-model="item.activityDetail"
                      :width="400"
                    ></v-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="areaTimes-r">
              <div class="opera">
                <v-button
                  text="删除"
                  v-if="index != 0"
                  @click="outRoom(item, index)"
                  type="warning"
                ></v-button>
                <v-button
                  v-if="index == form.detailList.length - 1"
                  text="新增"
                  @click="addRoom"
                ></v-button>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { mobileRegular } from "@/utils/regular";
import {
  getClassDetailForManageUrl,
  addClassInfoUrl,
  updateClassInfoAndDetailsUrl,
} from "./api.js";

export default {
  name: "partyMemberForm",
  data() {
    return {
      width: 250,
      submitConfig: {
        queryUrl: getClassDetailForManageUrl,
        submitUrl: "",
      },
      timeDuringOps: [
        { name: 1, value: "上午" },
        { name: 2, value: "下午" },
      ],
      form: {
        detailId: undefined,
        scheduleId: undefined,
        updateUser: undefined,
        dayName: "",
        timeDuring: "",
        applyBeginTime: "",
        applyEndTime: "",
        applicantsMax: "",
        detailList: [
          {
            activityBeginTime: "00:00",
            activityEndTime: "00:00",
            activityName: "",
            activityDetail: "",
          },
        ],
      },
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.form.applyBeginTime && !this.form.applyEndTime
          ? []
          : [this.form.applyBeginTime, this.form.applyEndTime];
      },
      set(val) {
        [this.form.applyBeginTime, this.form.applyEndTime] = val || ["", ""];
      },
    },
  },
  mounted() {
    const { id } = this.$route.query;
    this.form.detailId = id;
    this.submitConfig.submitUrl = id
      ? updateClassInfoAndDetailsUrl
      : addClassInfoUrl;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ scheduleId: id });
    }

    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
        // this.form.isPartyMember = this.form.isPartyMember
        //   ? String(this.form.isPartyMember)
        //   : "";
        // this.form.isCadre = this.form.isCadre ? String(this.form.isCadre) : "";
      });
    },
    submitBefore() {
      let params = {
        insertUser: !this.form.detailId
          ? this.$store.state.app.userInfo.userId
          : undefined,
        updateUser: this.form.detailId
          ? this.$store.state.app.userInfo.userId
          : undefined,
      };
      return {
        ...this.form,
        ...params,
      };
    },
    submitSuccess() {
      return true;
    },
    /* 活动场次 */
    outRoom(item, index) {
      this.form.detailList.splice(index, 1);
    },
    addRoom() {
      this.form.detailList.push({
        activityBeginTime: "00:00",
        activityEndTime: "00:00",
        activityName: "",
        activityDetail: "",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChildForm {
  box-sizing: border-box;
  height: 100%;

  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .areaTimes-l {
      margin-right: 20px;
    }
    .areaTimes-r {
      height: 57px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 20px 0;
      .item-l {
        margin-right: 10px;
      }
      .item-r {
        display: flex;
      }
    }
  }
}
</style>
