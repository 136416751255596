//获取托幼(分页)
const getClassDataForManageUrl = `/gateway/hc-external/nursery/getClassDataForManage`;

//删除课程
const deleteClassByIdUrl = `/gateway/hc-external/nursery/deleteClassById`;

//新增课程
const addClassInfoUrl = `/gateway/hc-external/nursery/addClassInfo`;
//编辑课程
const updateClassInfoAndDetailsUrl = `/gateway/hc-external/nursery/updateClassInfoAndDetails`;
//获取课程以及场次详情
const getClassDetailForManageUrl = `/gateway/hc-external/nursery/getClassDetailForManage`;

//获取课程意见反馈
const getClassFeedbackForManageUrl = `/gateway/hc-external/nursery/getClassFeedbackForManage`;
//获取课程意见反馈详情
const getFeedbackDetailUrl = `/gateway/hc-external/nursery/getFeedbackDetail`;

export {
  getClassDataForManageUrl,
  deleteClassByIdUrl,
  addClassInfoUrl,
  updateClassInfoAndDetailsUrl,
  getClassDetailForManageUrl,
  getClassFeedbackForManageUrl,
  getFeedbackDetailUrl,
};
